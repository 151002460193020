import { Component } from '@angular/core';

@Component({
  selector: 'pw-gyik',
  templateUrl: './gyik.component.html',
  styleUrls: ['./gyik.component.scss']
})
export class GyikComponent {

}
