
export const environment = {
    production: false,
    backendConfiguratorUrl : 'https://configurator.test.regenero.hu',
    apiUrl: 'https://test.regenero.hu/',
    downloadUrl:'https://file.test.regenero.hu/file/downloadFile',
    regeneroBaseUrl:'https://backend.test.regenero.hu'


};
