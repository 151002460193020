<div class="home-container">
    <div class="home">
        <h1>Üdvözöljük a Regenero Egészségház honlapján!</h1>
        <p>
            A Regenero Egészségház évtizedes múltra visszatekintő XIII. kerületi magánorvosi rendelő, melynek profiljába a szakterületek
            széles köre tartozik, többek között: ortopédia, kardiológia, endokrinológia, bőrgyógyászat, gasztroenterológia,
            laborvizsgálatok, reumatológia. Cégünk kiemelten fontosnak tartja, hogy a hozzánk forduló páciensek megkapják mindazt, amit egy
            magánorvosi rendelőtől várnak: tapasztalt,a szakterületükön kiemelkedő tudással rendelkező szakorvosokat, empatikus, segítőkész
            hozzáállást. Mindezt igényes, modern munkakörnyezetben. Hiszünk abban, hogy a legjobb reklám az elégedett páciens.
        </p>
        <div class="button">
            <div routerLink="/consultatinghours" class="consultation-time">
                <b [ngStyle]="{ padding: '8px', 'font-style': 'normal' }">TEKINTSE MEG SZAKRENDELÉSEINK IDŐPONTJAIT</b>
            </div>
            <div class="online-reserve">
                <a
                    [ngStyle]="{ 'text-decoration': 'none', color: '#413C3F' }"
                    href="https://medicall.cc/idopontfoglalas/regenero/index"
                    target="_blank"
                    ><b [ngStyle]="{ padding: '8px', 'font-style': 'normal' }">ONLINE IDŐPONT FOGLALÁS SZAKRENDELÉSRE</b></a
                >
            </div>
        </div>
        <pw-consultation></pw-consultation>
        <!-- <div *ngFor="let homeConfig of homeConfigList" class="newbooks-home">
            <div *ngIf="homeConfig.active">
                <pw-freshnews *ngIf="homeConfig.component === 'pw-freshnews'"></pw-freshnews>
            </div>
        </div> -->
    </div>
    <div class="consulting-hour-container">
        <div class="consulting-top">
            <div class="consulting-top-content">
                <div class="top">
                    <a href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">
                        <button type="button">
                            <mat-icon>calendar_today</mat-icon>
                            <span class="ml-2 mr-2">|</span><b>{{ 'Online időpontfoglalás' | uppercase }}</b>
                        </button>
                    </a>
                </div>
                <div class="top">
                    <a routerLink="/consultatinghours">
                        <button type="button">
                            <mat-icon>calendar_view_day</mat-icon>
                            <span class="ml-2 mr-2">|</span><b>{{ 'Szakrendelések időpontjai' | uppercase }}</b>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
