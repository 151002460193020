<div class="gyik-container">
    <div class="title-container">
        <div class="title">
            <h1>GYAKORI KÉRDÉSEK</h1>
        </div>
    </div>
    <div class="gyik">
        <div class="gyik-queries">
            <div class="gyik-query">
                <ul>
                    <li>
                        <div class="li-div">
                            <b>Hol tudok parkolni?</b
                            ><span
                                >Egészségházunk nem rendelkezik saját parkolóval, így a környező utcákban tud parkolni vagy a közeli Westend
                                bevásárlóközpont parkolóházát javasoljuk.</span
                            ><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b>Mennyivel korábban érdemes az időpont előtt érkezni?</b
                            ><span
                                >Amennyiben még sosem járt nálunk, az előzetes adminisztráció miatt érdemes 5-10 perccel korábban érkeznie
                                az időpontja előtt.</span
                            ><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b>Hogyan tudok fizetni?</b
                            ><span
                                >Lehetősége van készpénzzel, bankkártyával, illetve egészségpénztári kártyával is fizetnie. Egészségpénztári
                                partnereink listáját az alábbi linkre kattintva találja:
                            </span>
                            <a routerLink="/healthfund">Egészségpéntári partnereink</a><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b>Szükséges beutaló a szakrendelésekhez igénybevételéhez?</b
                            ><span
                                >Nem, beutalóra nincsen szüksége, azonban csak előre egyeztetett időpontokban tudjuk fogadni
                                pácienseinket.</span
                            ><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b>Nem rendelkezem érvényes TAJ számmal, ez esetben is tudnak fogadni?</b
                            ><span
                                >Természetesen igen, ez esetben szakorvosaink csak papíralapú receptet tudnak kiállítani, illetve az
                                ambuláns lapja nem kerül fel az EESZT rendszerbe.</span
                            ><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b>Mennyi idővel hamarabb kell lemondanom az időpont foglalásomat?</b
                            ><span
                                >Kérjük, amennyiben lehetősége van, legalább 48 órával hamarabb legyen kedves minket értesíteni, hogy a
                                felszabaduló helyet legyen lehetősége másnak igénybe venni.</span
                            ><br />
                        </div>
                    </li>
                    <li>
                        <div class="li-div">
                            <b
                                >SMS-ben értesítettek az időpontomról, azonban mégsem tudok megjelenni. Hogyan tudom lemondani az
                                időpontomat?</b
                            >
                            <span
                                >Fontos, hogy ne az sms-re válaszoljon! Bármelyik telefonos elérhetőségünkön
                                <i>(+ 361/400-9723 vagy +3630/385-4711)</i> vagy e-mailen keresztül <i>regenero@regenero.hu</i> tudja
                                törölni időpontját.</span
                            ><br />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
