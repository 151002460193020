<div class="home-container">
    <div class="home">
        <div class="title-container">
            <div class="title">
                <h1>Ismerje meg a Regenero Egészségház szolgáltatásait!</h1>
                <p>
                    A Regenero Egészségház évtizedes múltra visszatekintő szakrendelő Budapest XIII. kerületében, melynek profiljába a
                    szakterületek széles köre tartozik, laborvizsgálatokkal egyaránt!
                </p>
            </div>
        </div>
        <div class="button">
            <div routerLink="/consultatinghours" class="consultation-time">
                <b [ngStyle]="{ padding: '8px', 'font-style': 'normal' }">TEKINTSE MEG SZAKRENDELÉSEINK IDŐPONTJAIT</b>
            </div>
            <div class="online-reserve">
                <a
                    [ngStyle]="{ 'text-decoration': 'none', color: '#413C3F' }"
                    href="https://medicall.cc/idopontfoglalas/regenero/index"
                    target="_blank"
                    ><b [ngStyle]="{ padding: '8px', 'font-style': 'normal' }">ONLINE IDŐPONT FOGLALÁS SZAKRENDELÉSRE</b></a
                >
            </div>
        </div>
        <div class="consultation-container">
            <pw-consultation></pw-consultation>
        </div>
        <div class="health-fund-container">
            <div class="health-fund">
                <h2>Akár egészségkártyával is!</h2>
                <div class="health-funds-text">
                    <p>
                        Önnek is van egészségpénztára? A Regenero számos egészségpénztári partnerrel áll már szerződésben, nagy eséllyel az
                        önével is!
                    </p>
                    <p>
                        Nézze meg folyamatosan bővülő partnereink listáját, hogy az Ön számára fontos vizsgálatokat egészségkártyájával is
                        tudja fizetni!
                    </p>
                    <p>
                        Nem találja partnereink között az Ön egészségpénztárát? Ne csüggedjen, csak egy telefonjába kerül, hogy rendszeresen
                        bővülő listánk egy Ön által kívánt egészségpénztárral gyarapodjon!
                    </p>
                </div>
                <div class="health-button">
                    <button routerLink="/healthfund">EGÉSZSÉGPÉNZTÁRI PARTNEREINK</button>
                </div>
            </div>
        </div>
        <div class="regenero-container">
            <div class="regenero">
                <h2>Mi vagyunk a Regenero Egészségház</h2>
                <p>
                    A Regenero Egészségház évtizedes múltra visszatekintő XIII. kerületi magánorvosi rendelő, melynek profiljába a
                    szakterületek széles köre tartozik, többek között: ortopédia, kardiológia, endokrinológia, bőrgyógyászat,
                    gasztroenterológia, laborvizsgálatok, reumatológia. Cégünk kiemelten fontosnak tartja, hogy a hozzánk forduló páciensek
                    megkapják mindazt, amit egy magánorvosi rendelőtől várnak: tapasztalt,a szakterületükön kiemelkedő tudással rendelkező
                    szakorvosokat, empatikus, segítőkész hozzáállást. Mindezt igényes, modern munkakörnyezetben. Hiszünk abban, hogy a
                    legjobb reklám az elégedett páciens.
                </p>
                <a href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">FOGLALJON HOZZÁNK IDŐPONTOT!</a>
            </div>
        </div>

        <!-- <div *ngFor="let homeConfig of homeConfigList" class="newbooks-home">
            <div *ngIf="homeConfig.active">
                <pw-freshnews *ngIf="homeConfig.component === 'pw-freshnews'"></pw-freshnews>
            </div>
        </div> -->
        <div class="blogs">
            <h2>Híreink a rendelőből</h2>
            <app-blogs></app-blogs>
        </div>
        <div class="map">
            <div class="map-container">
                <div class="google-map">
                    <iframe width="500" height="320" frameborder="0" [src]="googleMapIframeSrc"></iframe>
                </div>
                <div class="google-map-mobile">
                    <iframe width="100%" height="100%" frameborder="0" [src]="googleMapIframeSrc"></iframe>
                </div>
                <div class="address">
                    <div>Látogasson el hozzánk!</div>
                    <div>Ha minőségi orvosi ellátásra vágyik, lépjen velünk kapcsolatba, foglaljon hozzánk időpontot konzultációra!</div>
                    <div>
                        Felújított, korszerű egészségügyi rendelőnk a 13. kerületben található Budapesten, mindössze pár percre a Lehel
                        tértől!
                    </div>
                    <div>Címünk: 1132 Budapest, Csanády utca 1-3.</div>
                    <div>Könnyedén megközelíthetők vagyunk tömegközlekedéssel:</div>
                    <div>
                        <ul>
                            <li>M3-as metró (Lehel tér)</li>
                            <li>14-es villamos (Lehel tér)</li>
                            <li>15-ös busz (Csanády utca)</li>
                            <li>76-os troli (Csanády utca)</li>
                        </ul>
                    </div>
                    <div>Szeretettel várjuk rendelőnkben!</div>
                    <div>
                        <button href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">IDŐPONTOT FOGLALOK!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="consulting-hour-container">
        <div class="consulting-top">
            <div class="consulting-top-content">
                <div class="top">
                    <a href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">
                        <button type="button">
                            <mat-icon>calendar_today</mat-icon>
                            <span class="ml-2 mr-2">|</span><b>{{ 'Online időpontfoglalás' | uppercase }}</b>
                        </button>
                    </a>
                </div>
                <div class="top">
                    <a routerLink="/consultatinghours">
                        <button type="button">
                            <mat-icon>calendar_view_day</mat-icon>
                            <span class="ml-2 mr-2">|</span><b>{{ 'Szakrendelések időpontjai' | uppercase }}</b>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
