<div *ngIf="_router.url !== '/'" class="blog-containers">
    <div class="title-home-blog">
        <div *ngIf="_router.url !== '/'" routerLinkActive="router-link-active" class="title-blog">
            <h3>{{ 'home.newsletterBlogs' | translate }}</h3>
        </div>
    </div>
    <div class="image-container">
        <div class="books">
            <div routerLink="{{ banner.routerLink }}" *ngFor="let banner of bannerCrumbs" class="book">
                <a><img class="blog-thumb-img" [src]="banner.thumbnailUrl" /></a>
                <div class="blog-title">
                    <h4>{{ banner.title }}</h4>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{ 'padding-bottom': '0' }" *ngIf="_router.url === '/'" class="blog-containers">
    <div class="title-home-blog">
        <div *ngIf="_router.url !== '/'" routerLinkActive="router-link-active" class="title-blog">
            <h3>{{ 'home.newsletterBlogs' | translate }}</h3>
        </div>
    </div>
    <div [ngStyle]="{ 'padding-bottom': '0' }" class="image-container">
        <div class="books">
            <div routerLink="{{ banner.routerLink }}" *ngFor="let banner of bannerCrumbsHome" class="book">
                <a><img class="blog-thumb-img" [src]="banner.thumbnailUrl" /></a>
                <div class="blog-title">
                    <h4>{{ banner.title }}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
