<div class="description-container">
    <div class="title">
        <h2>{{ speciality.name | uppercase }}</h2>
    </div>
    <div class="description">
        <div class="des" [innerHTML]="speciality.description"></div>
    </div>
    <h3 [ngStyle]="{ 'text-align': 'start', width: '90%' }">{{ speciality.specialityType?.jobTitle }}</h3>
    <div class="doctor-container">
        <div *ngFor="let doctor of doctorList" class="doctor">
            <div [routerLink]="'/doctor/' + doctor.routerLink" class="datas">
                <img [src]="doctor.image" />
                <span>{{ doctor.name | uppercase }}</span>
            </div>
        </div>
    </div>
</div>
