import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSpecialitiesResponse } from '../consultation/getspecialitiesresponse';
import { Speciality } from '../consultation/speciality';
import { SpecialityWithPrices } from '../consultation/specialitywithprices';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SpecialityDoctorsComponent } from '../specialitydoctors/specialitydoctors.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'pw-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})
export class PricelistComponent implements OnInit {
    regeneroBaseUrl = '';

    getSpecialitiesUrl = '/regenero/get-specialities';

    specialities?: Speciality[];

    showExamination = false;

    chosenSpecialityId = 0;

    hasSpecialityChosen = false;

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService){
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                this.specialities = response.specialityList;

            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    showExaminationElement() {
        this.specialities!.forEach((speciality) => {
            speciality.hasSpecialityChosen = false;
            speciality.isVisible = false;
        })
        this.showExamination = !this.showExamination;
    }

    chooseOneSpeciality(speciality: SpecialityWithPrices) {
        this.chosenSpecialityId = speciality.speciality?.id!;
        speciality.hasSpecialityChosen = !speciality.hasSpecialityChosen;
        speciality.isVisible = !speciality.isVisible;
    }


}
