<div class="app">
    <pw-headermodule
        [nameOfTheSite]="nameOfTheSite"
        [logoUrl]="logoUrl!"
        [facebookImgUrl]="facebookImgUrl"
        [facebookURL]="facebookUrl"
        [hasSearchBar]="false"
        [hasLogo]="true"
        [hasShoppingCart]="false"
        [hasLogin]="false"
        [colorOfNavBar]="colorOfNavBar"
        [logoSize]="logoSize"
        [isRegenero]="true"
        [navigationMenu]="navigationMenu"
        [hasMatMenu]="false"
        [aszfUrl]="aszfUrl"
        [dataManagamentUrl]="dataManagamentUrl!"
        [isRowView]="isRowView"
        [colorOfMobileSidenav]="colorOfMobileSidenav"
        [hasMenuTitle]="false"
    ></pw-headermodule>
    <div class="app-slideshow">
        <app-slideshow *ngIf="router.url === '/'"></app-slideshow>
    </div>
    <pw-breadcrumbmodule></pw-breadcrumbmodule>
    <div class="router-outlet">
        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
    <pw-footermodule [facebookURL]="facebookUrl" [footer]="footer"></pw-footermodule>
</div>
<ng-template #footer>
    <div class="elements">
        <div class="documents">
            <div class="content">
                <div class="documents-inside-box">
                    <div>
                        <div><h2 [ngStyle]="{ color: 'white' }">Kérdése merült fel?</h2></div>
                        <div>Forduljon hozzánk bizalommal, igyekszünk mihamarabb segíteni!</div>
                        <div>
                            Keresse kollégáinkat a regenero@regenero.hu e-mailcímen, nézzen szét a “Gyakran Ismételt Kérdések - GYIK”
                            menüpontban, vagy hívjon minket a +36 30 385 4711 telefonszámon!
                        </div>
                        <div><button>GYAKRAN ISMÉTELT KÉRDÉSEK</button></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="regenero-infos">
            <div class="contact-container">
                <div class="regenero">
                    <br />
                </div>
                <div class="contact">
                    <div class="logo"><img src="./assets/images/Regnero_logo_egeszseghaz_feher.png" /></div>
                    <div class="copy">
                        <div class="spans">
                            <div class="span-container">
                                <div><h3>OLDALTÉRKÉP</h3></div>
                                <div class="li">
                                    <ul>
                                        <li>
                                            <a routerLink="/"><span>Főoldal</span></a>
                                        </li>
                                        <li>
                                            <a routerLink="/consultation"><span>Szakrendelések</span></a>
                                        </li>
                                        <li>
                                            <a href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank"
                                                ><span>Időpontfoglalás</span></a
                                            >
                                        </li>
                                        <li>
                                            <a routerLink="/pricelist"><span>Árlista</span></a>
                                        </li>
                                        <li>
                                            <a routerLink="/gyik"><span>GYIK</span></a>
                                        </li>
                                        <li>
                                            <a routerLink="/introduction"><span>Kapcsolat</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="span-container">
                                <div>
                                    <h3>NYITVATARTÁS</h3>
                                </div>
                                <div class="text"><span>H: 8:00 - 20:00</span></div>
                                <div class="text"><span>K: 7:00 - 20:00</span></div>
                                <div class="text"><span>Sze: 8:00 - 20:00</span></div>
                                <div class="text"><span>Cs: 8:00 - 20:00</span></div>
                                <div class="text"><span>P: 7:00 - 20:00</span></div>
                                <div class="text"><span>Szo: 8:00 - 14:00</span></div>
                            </div>
                            <div class="span-container">
                                <div>
                                    <h3>KAPCSOLAT</h3>
                                </div>
                                <div class="text">
                                    <span>{{ email }}</span>
                                </div>
                                <div class="text">
                                    <span>{{ phoneNumber }}</span>
                                </div>
                                <div class="text">
                                    <span>{{ address }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="copyright-container">
                            <div class="copyright">
                                <div class="text-copy"><mat-icon>copyright</mat-icon><span>Regenero Egészségház Kft</span></div>
                                <div class="text-copy">
                                    <a routerLink="/healthfund"><span>Egészségpénztárak</span></a>
                                </div>
                                <div class="text-copy-opening">
                                    <a href="{{ dataManagamentUrl }}" target="_blank"><span>Adatkezelési tájékoztató</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="elements">
        <div class="documents">
            <div class="content">
                <div class="documents-inside-box">
                    <div class="privacy-policy">
                        <a class="policy" routerLink="/healthfund" target="_blank"><span>EGÉSZSÉGPÉNZTÁRAK</span></a>
                    </div>
                    <div class="privacy-policy">
                        <a class="policy" href="{{ dataManagamentUrl }}" target="_blank"
                            ><span>{{ 'footer.policy' | translate }}</span></a
                        >
                    </div>
                </div>
                <div class="logo"><img [src]="logoUrl" /></div>
            </div>
        </div>
        <div class="regenero-infos">
            <div class="contact-container">
                <div class="regenero">
                    <a routerLink="/">{{ 'footer.regenero' | translate }}</a
                    ><br />
                </div>
                <div class="contact">
                    <div class="span-container">
                        <span>{{ email }}</span>
                    </div>
                    <div class="span-container">
                        <span>{{ address }}</span>
                    </div>
                    <div class="span-container">
                        <span>{{ phoneNumber }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</ng-template>
