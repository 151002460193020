import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'pw-movingpopup',
    templateUrl: './movingpopup.component.html',
    styleUrls: ['./movingpopup.component.scss']
})
export class MovingpopupComponent {

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        private _matdialog: MatDialog
    ) {


        this.selectedAlign = 'end';
    }

    closeDialog() {
        this._matdialog.closeAll();
    }
}
