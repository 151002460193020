import { AfterViewChecked, ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { Doctor } from '../doctor';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { FindDoctorResponse } from './finddoctorresponse';
import { FindDoctorRequest } from './finddoctorrequest';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DoctorConsultingHour } from './doctorconsultinghour';
import { FIndConsultingHoursRequest } from './findConsultingHoursRequest';
import { FindConsultingHoursResponse } from './findConsultingHoursResponse';
import { DomSanitizer } from '@angular/platform-browser';
import { GetDoctorSpecialitiesRequest } from './getdoctorspecialitiesrequest';
import { GetDoctorSpecialitiesResponse } from './getdoctorspecialitiesresponse';
import { Speciality } from '../consultation/speciality';

@Component({
    selector: 'pw-doctor',
    templateUrl: './doctor.component.html',
    styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent implements OnInit,AfterViewChecked {
    doctor: Doctor = {};

    findDoctorUrl = '/regenero/find-doctor-by-routerlink';

    findAllConsultingHoursUrl = '/regenero/find-consulting-hour';

    getSpecialitiesForADoctorUrl = '/regenero/get-doctor-specialities';

    regeneroBaseUrl = '';

    downloadFileUrl = '';

    routerLink = '';

    doctorConsultingHoursList:DoctorConsultingHour[] = [];

    specialities?:Speciality[];

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private ref: ChangeDetectorRef,
        private ngZone: NgZone,
        private readonly _domSanitizer: DomSanitizer

    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.regeneroBaseUrl = _regeneroBaseUrl;
    }
    ngAfterViewChecked(): void {
        this.ngZone.runOutsideAngular(() => {
            this.ref.detectChanges();
            setTimeout(() => {
                const lastSegmentOfUrl = window.location.href.split('/').pop();
                const routerLink = lastSegmentOfUrl as string;
                if(this.routerLink!==routerLink) {
                    window.location.reload();
                }
            }, 0);
        });
    }
    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        this.routerLink = lastSegmentOfUrl as string;

        const findDoctorRequest: FindDoctorRequest = {
            routerLink: this.routerLink
        };

        this._apiRequestService
            .sendRequest<FindDoctorRequest, FindDoctorResponse>(this.regeneroBaseUrl, true, findDoctorRequest, true, this.findDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindDoctorResponse) => {
                this.doctor = response.doctor!;
                this.doctor.introduction = this._domSanitizer.bypassSecurityTrustHtml(this.doctor.introduction!) as string;

                this.getConsultingHours(this.doctor.id!);



            });
    }

    getConsultingHours(id:number) {
        const findConsultingHoursRequest:FIndConsultingHoursRequest = {
            doctorId:id
        }

        this._apiRequestService
        .sendRequest<FIndConsultingHoursRequest, FindConsultingHoursResponse>(this.regeneroBaseUrl, true, findConsultingHoursRequest, true, this.findAllConsultingHoursUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();

                    this.router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response: FindConsultingHoursResponse) => {
            this.doctorConsultingHoursList = response.doctorConsultingHourList!;
            console.log(this.doctorConsultingHoursList)
            this.getSpecialitiesForDoctor(id);

        });
    }

    getSpecialitiesForDoctor(id:number) {
        const getSpecialitiesDoctorRequest:GetDoctorSpecialitiesRequest = {
            doctorId:id
        }

        this._apiRequestService
        .sendRequest<GetDoctorSpecialitiesRequest, GetDoctorSpecialitiesResponse>(this.regeneroBaseUrl, true, getSpecialitiesDoctorRequest, true, this.getSpecialitiesForADoctorUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();

                    this.router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response: GetDoctorSpecialitiesResponse) => {
            this.specialities = response.specialityList!;



        });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }


}
