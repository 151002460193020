import { HttpClient } from '@angular/common/http';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Language, NavigationMenuItem, NavigationMenuPosition } from '@prekog/pw-common';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'ap-navigationregenero',
    templateUrl: './navigationregenero.component.html',
    styleUrls: ['./navigationregenero.component.scss']
})
export class NavigationregeneroComponent implements OnInit {
    displaySubNav = false;

    @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

    menuIcon = 'menu';

    @Input() hasLogo = true;

    @Input() logoUrl = '';

    @Input() isShoppingCart = true;

    @Input() hasLogin = true;

    @Input() colorOfNavBar = 'primaryColor';

    @Input() isAdmin = false;

    @Input() isSubMenuFullScreen = false;

    showLogin = 'BEJELENTKEZÉS / REGISZTRÁCIÓ';

    home = 'navigation.home';

    @ViewChildren('icon') icon!: QueryList<ElementRef>;

    id = '';

    isHamburger = true;

    languages: Language[] = [];

    leftNavigationMenu: NavigationMenuItem[] = [];

    loginMenu = false;

    @Input() logoPosition = '';

    @Input() navigationMenu: NavigationMenuItem[] = [];

    rightNavigationMenu: NavigationMenuItem[] = [];

    @ViewChild(MatSidenav)
    sidenav!: MatSidenav;

    whichLogoDisplays = false;

    @Input() nameOfTheSite = '';

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';
    isLoggedIn = false;

    menuState: string = 'closed';

    @ViewChild('menu') menu?: MatMenu;

    showSubmenu = false;

    showSubSubMenuu = false;

    submenu: NavigationMenuItem[] = [];

    subSubMenu: NavigationMenuItem[] = [];

    isMobile = true;

    isCollapsed = true;

    submenuOpened = false;

    ids = 0;

    title = 'Regenero Egészségház';

    cookieValue = '';

    facebookImgUrl = '';

    facebookUrl = 'https://www.facebook.com/profile.php?id=100063541752114';

    loading = false;

    production = false;

    downloadFileUrl = '';

    aszfUrl = '';

    dataManagamentUrl = 'http://localhost:8088/file/downloadFile/20240627T085717908963614';

    simplePayLogo = '';

    logoSize = '';

    subsubMenu: NavigationMenuItem[] = [];

    subsubmenuopened = false;

    idSub = '';

    constructor(
        private _router: Router,
        private _httpClient: HttpClient,
        private observer: BreakpointObserver,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.logoUrl = this.downloadFileUrl + '/20240626T101258865160117';
    }

    ngOnInit(): void {
        this._httpClient.get('./assets/configurations/navigation-menu.json').subscribe((navigationMenu) => {
            this.navigationMenu = navigationMenu as NavigationMenuItem[];
            console.log(navigationMenu);
            this.observer.observe(['(max-width: 780px)']).subscribe((screenSize) => {
                if (screenSize.matches) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
        });
    }

    toggleMenu() {
        if (this.isMobile) {
            this.sidenav.toggle();
            this.isCollapsed = false;
        } else {
            this.sidenav.toggle();
            this.isCollapsed = !this.isCollapsed;
        }
    }

    toggleSubMenu() {
        this.submenuOpened = !this.submenuOpened;
    }

    routeOnClick(navigation: NavigationMenuItem) {
        if (!navigation.hasDynamicSubMenu) {
            this._router.navigateByUrl(navigation.id!);
            if (this.isMobile) {
                this.sidenav.close();
            }
        } else if (navigation.hasDynamicSubMenu) {
            this.submenu = navigation.staticSubMenus!;
            this.id = navigation.id!;
            console.log(this.id);
            this.toggleSubMenu();
        }
    }

    routeOnClickk(navigation: NavigationMenuItem) {
        console.log(navigation);
        if (!navigation.hasDynamicSubMenu) {
            this._router.navigateByUrl(navigation.id!);
            if (this.isMobile) {
                this.sidenav.close();
            }
        } else if (navigation.hasDynamicSubMenu) {
            this.submenu = navigation.staticSubMenus!;
            this.idSub = navigation.id!;
            console.log(this.id);
            this.subsubmenuopened = !this.subsubmenuopened;

        }
    }

    // constructor(
    //     private _matDialog: MatDialog,
    //     private _obServer: BreakpointObserver,
    //     private _http: HttpClient,
    //     private ref: ChangeDetectorRef,
    //     private ngZone: NgZone,
    //     private _router: Router
    // ) {}

    // ngOnInit(): void {
    //     this.getNavigationMenu('./assets/configurations/navigation-menu.json').subscribe((navigationMenu)=> {
    //             navigationMenu.shift();
    //             this.navigationMenu = navigationMenu;
    //             this.orderNavigationMenus(this.navigationMenu);

    //     })

    // }

    // getNavigationMenu(url: string): Observable<NavigationMenuItem[]> {
    //     return this._http.get<NavigationMenuItem[]>(url);
    // }
    // orderNavigationMenus(navigationMenus: NavigationMenuItem[]) {
    //     navigationMenus.map((menu: NavigationMenuItem) => {
    //         if (menu.positionOnNavBar === NavigationMenuPosition.right) {
    //             this.rightNavigationMenu.push(menu);
    //         } else {
    //             this.leftNavigationMenu.push(menu);
    //         }

    //     });
    // }

    // showSubMenu(navigation: NavigationMenuItem) {
    //     if (navigation.hasDynamicSubMenu) {
    //         this.submenu = navigation.staticSubMenus as NavigationMenuItem[];
    //         this.showSubmenu = !this.showSubmenu;
    //     } else {
    //         this.showSubmenu = false;
    //         this._router.navigateByUrl(navigation.id!);
    //     }
    // }

    // showSubSubMenu(navigation: NavigationMenuItem) {
    //     if (navigation.hasDynamicSubMenu) {
    //         this.subSubMenu = navigation.staticSubMenus as NavigationMenuItem[];
    //         this.showSubSubMenuu = !this.showSubSubMenuu;
    //     } else {
    //         this.showSubSubMenuu = false;
    //         this._router.navigateByUrl(navigation.id!);
    //     }
    // }

    // closeSubMenu() {
    //     this.showSubmenu = false;
    // }
}
