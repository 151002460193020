<div *ngIf="router.url === '/'" class="speciality-container">
    <div class="title-container">
        <div class="title">
            <h1>Szakrendeléseink</h1>
        </div>
    </div>
    <!-- routerLink="/specialitydoctors/{{ speciality.id }}" - class= speciality element routerLinkje -->
    <div class="speciality">
        <div class="spec">
            <div
                routerLink="/consultation/{{ speciality.id }}"
                *ngFor="let speciality of specialities; let i = index"
                class="speciality-element"
            >
                <div class="name-container">
                    <div class="name">
                        <div>
                            <img [src]="speciality.imageUrl" />
                        </div>
                        <p>{{ speciality.name | uppercase }}</p>
                    </div>
                </div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>
