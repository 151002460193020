import { Component } from '@angular/core';

@Component({
  selector: 'pw-healthfund',
  templateUrl: './healthfund.component.html',
  styleUrls: ['./healthfund.component.scss']
})
export class HealthfundComponent {

}
