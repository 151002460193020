import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { GetDoctorsByRequest } from './getdoctorsbyrequest';
import { Doctor } from '../doctor';
import { GetDoctorsByResponse } from './getdoctorsbyresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FIndConsultingHoursRequest } from '../doctor/findConsultingHoursRequest';
import { FindConsultingHoursResponse } from '../doctor/findConsultingHoursResponse';
import { DoctorConsultingHour } from '../doctor/doctorconsultinghour';
import { DoctorWithConsultingHours } from './doctorconsultinghours';
import { GetSpecialityRequest } from './getspecialityrequest';
import { GetSpecialityResponse } from './getspecialityresponse';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'pw-specialitydoctors',
    templateUrl: './specialitydoctors.component.html',
    styleUrls: ['./specialitydoctors.component.scss']
})
export class SpecialityDoctorsComponent implements OnInit {
    iframeUrl = '';

    getDoctorsBySpecialityUrl = '/regenero/get-doctors-by-speciality';

    regeneroBaseUrl = '';

    doctors?: Doctor[];

    specialityId1 = 0;

    findAllConsultingHoursUrl = '/regenero/find-consulting-hour';

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    doctorWithConsultingHours: DoctorWithConsultingHours[] = [];

    getSpecialityByIdUrl = '/regenero/get-speciality-by-id';

    specialityName = '';

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        @Inject(MAT_DIALOG_DATA) public specialityId:number,
        private _dialog:MatDialog,
        private _router:Router
    ) {
        this.regeneroBaseUrl = _regeneroBaseUrl;

        this.selectedAlign = 'end';
    }

    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.specialityId1 = +routerLink;

        const getdoctorsbyrequest = {
            specialityId: this.specialityId
        };
        this._apiRequestService
            .sendRequest<GetDoctorsByRequest, GetDoctorsByResponse>(
                this.regeneroBaseUrl,
                true,
                getdoctorsbyrequest,
                true,
                this.getDoctorsBySpecialityUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetDoctorsByResponse) => {
                this.doctors = response.doctorList!;
                //this.iframeUrl = this.doctors[0].reservationLink!;
                this.getSpecialityName();
                this.doctors.forEach((doctor) => {
                    this.getConsultingHours(doctor);
                });
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    getConsultingHours(doctor: Doctor) {
        const findConsultingHoursRequest: FIndConsultingHoursRequest = {
            doctorId: doctor.id
        };

        this._apiRequestService
            .sendRequest<FIndConsultingHoursRequest, FindConsultingHoursResponse>(
                this.regeneroBaseUrl,
                true,
                findConsultingHoursRequest,
                true,
                this.findAllConsultingHoursUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: FindConsultingHoursResponse) => {
                this.doctorConsultingHoursList = response.doctorConsultingHourList!;

                const doctorWithConsultingHours = {
                    doctor: doctor,
                    consultingHours: this.doctorConsultingHoursList
                };

                this.doctorWithConsultingHours.push(doctorWithConsultingHours);

                console.log(this.doctorWithConsultingHours);
            });
    }

    getSpecialityName() {
        const getspecialitybyidRequest: GetSpecialityRequest = {
            specialityId: this.specialityId
        };
        this._apiRequestService
            .sendRequest<GetSpecialityRequest, GetSpecialityResponse>(
                this.regeneroBaseUrl,
                true,
                getspecialitybyidRequest,
                true,
                this.getSpecialityByIdUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                        this.router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: GetSpecialityResponse) => {
                this.specialityName = response.speciality?.name!;
            });
    }

    closeDialog() {
        this._dialog.closeAll();
        this._router.navigateByUrl('/consultation');

    }

    closeDialogg() {
        this._dialog.closeAll();


    }
}
