<div class="popup-main">
    <div class="popup">
        <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
            <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
        <div class="text">
            <h3 [ngStyle]="{ 'text-align': 'center' }">Tisztelt Páciensünk!</h3>
            <h3>
                A Regenero Egészségház új helyre költözött.<br />Új címünk:
                <label [ngStyle]="{ 'font-weight': 'normal', 'font-style': 'italic' }">1132 Budapest, Csanády utca 1-3.</label
                ><br />Kérésére recepciós kollégáink szívesen útbaigazítják.<br />Telefonszámunk:
                <label [ngStyle]="{ 'font-weight': 'normal', 'font-style': 'italic' }">+36-1-400-97-23 vagy +36-30-385-47-11</label>
            </h3>
            <br />
        </div>
    </div>
</div>
