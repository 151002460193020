<div class="pricelist-container">
    <div class="speciality-pricelist-container">
        <div class="title-container">
            <div class="title">
                <div class="pricelist">
                    <h2>ÁRLISTA</h2>
                </div>
                <div class="healthfund">
                    <h3>
                        Intézetünkben bankkártyával és <a routerLink="/healthfund"><span>egészségkártyával </span></a>is fizethet.
                    </h3>
                </div>
            </div>
        </div>
        <div class="speciality">
            <div class="icons">
                <div class="icon">
                    <mat-icon *ngIf="!showExamination" (click)="showExaminationElement()">add_circle</mat-icon>
                    <mat-icon *ngIf="showExamination" (click)="showExaminationElement()">remove_circle_outline</mat-icon>
                </div>
            </div>
            <div class="spec">
                <div *ngFor="let speciality of specialities; let i = index" class="speciality-element">
                    <div class="name-container">
                        <div (click)="chooseOneSpeciality(speciality)" class="name">
                            <p>
                                <b [ngStyle]="{ 'font-size': '18px' }">{{ speciality.name! | uppercase }}</b>
                            </p>
                        </div>
                        <div *ngIf="speciality.hasSpecialityChosen && speciality.isVisible" class="exam">
                            <div
                                [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#f2f2f2' }"
                                class="examination-container"
                                *ngFor="let examination of speciality.examinationList; let i = index"
                            >
                                <div class="examination">
                                    <div class="examination-name">
                                        <span>{{ examination.name }}</span>
                                    </div>
                                    <div *ngIf="examination.price" class="examination-price">
                                        <span>{{ examination.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                                    </div>
                                    <div *ngIf="!examination.price" class="examination-price">
                                        <span
                                            >{{ examination.minPrice | currency : 'Ft' : 'symbol' : '1.0-0' }} -
                                            {{ examination.maxPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="showExamination" class="exam">
                            <div
                                [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#f2f2f2' }"
                                class="examination-container"
                                *ngFor="let examination of speciality.examinationList; let i = index"
                            >
                                <div class="examination">
                                    <div class="examination-name">
                                        <span>{{ examination.name }}</span>
                                    </div>
                                    <div class="examination-price">
                                        <span>{{ examination.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
