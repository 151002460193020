<div class="app">
    <pw-headermodule
        [nameOfTheSite]="nameOfTheSite"
        [logoUrl]="logoUrl!"
        [facebookImgUrl]="facebookImgUrl"
        [facebookURL]="facebookUrl"
        [hasSearchBar]="false"
        [hasLogo]="true"
        [hasShoppingCart]="false"
        [hasLogin]="false"
        [colorOfNavBar]="colorOfNavBar"
        [logoSize]="logoSize"
        [isRegenero]="true"
        [navigationMenu]="navigationMenu"
        [hasMatMenu]="false"
        [aszfUrl]="aszfUrl"
        [dataManagamentUrl]="dataManagamentUrl!"
        [isSideNav]="false"
        [isRowView]="isRowView"
        [colorOfMobileSidenav]="colorOfMobileSidenav"
        [hasMenuTitle]="false"
    ></pw-headermodule>
    <div class="app-slideshow">
        <app-slideshow *ngIf="router.url === '/'"></app-slideshow>
    </div>
    <pw-breadcrumbmodule></pw-breadcrumbmodule>
    <div class="router-outlet">
        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
    <pw-footermodule [facebookURL]="facebookUrl" [footer]="footer"></pw-footermodule>
</div>
<ng-template #footer>
    <div class="elements">
        <div class="documents">
            <div class="content">
                <div class="documents-inside-box">
                    <div class="privacy-policy">
                        <a class="policy" routerLink="/healthfund" target="_blank"><span>EGÉSZSÉGPÉNZTÁRAK</span></a>
                    </div>
                    <div class="privacy-policy">
                        <a class="policy" href="{{ dataManagamentUrl }}" target="_blank"
                            ><span>{{ 'footer.policy' | translate }}</span></a
                        >
                    </div>
                </div>
                <div class="logo"><img [src]="logoUrl" /></div>
            </div>
        </div>
        <div class="regenero-infos">
            <div class="contact-container">
                <div class="regenero">
                    <a routerLink="/">{{ 'footer.regenero' | translate }}</a
                    ><br />
                </div>
                <div class="contact">
                    <div class="span-container">
                        <span>{{ email }}</span>
                    </div>
                    <div class="span-container">
                        <span>{{ address }}</span>
                    </div>
                    <div class="span-container">
                        <span>{{ phoneNumber }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
