<div class="doctor-container">
    <div class="doctor">
        <div *ngIf="doctor.name !== 'labor'" class="name">
            <h2>{{ doctor.name }},&ensp;</h2>
            <div *ngFor="let speciality of specialities; let i = index">
                <div>
                    <h2 *ngIf="speciality !== specialities![specialities!.length - 1]">{{ speciality.jobTitle }},&ensp;</h2>
                    <h2 *ngIf="speciality === specialities![specialities!.length - 1]">{{ speciality.jobTitle }}</h2>
                </div>
            </div>
        </div>
        <div class="doctor-image-consulting-container">
            <div class="image-consulting-hour-container">
                <div class="image">
                    <img [src]="doctor.image" />
                </div>
                <div class="consulting-hour-container">
                    <h3>Rendelési idő:</h3>
                    <div class="consult">
                        <div *ngFor="let consultingHour of doctorConsultingHoursList" class="consulting-hours">
                            <div class="consulting-hour">
                                <div class="span-class">
                                    <span *ngIf="consultingHour.startTime !== null && consultingHour.endTime !== null">
                                        {{ consultingHour.day }}: {{ consultingHour.startTime }} - {{ consultingHour.endTime }}</span
                                    >
                                </div>
                                <div class="span-class">
                                    <span *ngIf="consultingHour.startTime === null && consultingHour.endTime === null"
                                        >{{ consultingHour.day }}: változó</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="iframe-intr">
                <div *ngIf="doctor.reservationLink" class="iframe">
                    <iframe
                        class="responsive-iframe"
                        title="Időpontfoglalás"
                        [src]="doctor.reservationLink! | iframeUrl"
                        width="800"
                        height="500"
                        frameborder="0"
                    ></iframe>
                </div>
                <div class="introduction">
                    <!-- <p>{{ doctor.introduction }}</p> -->
                    <div [innerHTML]="doctor.introduction"></div>
                </div>
            </div>
        </div>
    </div>
</div>
