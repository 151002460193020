import { Component, Inject, OnInit } from '@angular/core';
import { HomeConfig } from './home-config';
import { ApiRequestService, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HomeConfigResponse } from './homeconfigresponse';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'pw-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    getHomeConfigsUrl = '/websiteconfigurator/get-homeconfigs';

    homeConfigList: HomeConfig[] = [];

    configuratorBaseUrl = '';
    googleMapIframeSrc = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBSYe-gDScK77G0sZGbV3Ab_FFKizyP_xo&q=Regenero';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService,
        private readonly _domSanitizer: DomSanitizer
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, HomeConfigResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeConfigsUrl)
            .subscribe((response) => {
                this.homeConfigList = response.homeConfigList as HomeConfig[];
                this.googleMapIframeSrc = this._domSanitizer.bypassSecurityTrustResourceUrl(this.googleMapIframeSrc) as string;
            });

    }
}
