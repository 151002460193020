<div [ngStyle]="{ 'padding-bottom': '8px' }" *ngIf="router.url === '/'" class="speciality-container">
    <div class="title-container"></div>
    <!-- routerLink="/specialitydoctors/{{ speciality.id }}" - class= speciality element routerLinkje -->
    <div class="speciality">
        <div class="spec">
            <div
                routerLink="/consultation/{{ speciality.id }}"
                *ngFor="let speciality of specialities; let i = index"
                class="speciality-element"
            >
                <div class="name-container">
                    <div class="name">
                        <div>
                            <img [src]="speciality.imageUrl" />
                        </div>
                        <p>{{ speciality.name | uppercase }}</p>
                    </div>
                </div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
</div>

<div *ngIf="router.url !== '/'" [ngStyle]="{ 'align-items': 'center' }" class="speciality-container">
    <div [ngStyle]="{ width: '90%', 'align-items': 'center' }" class="title-container">
        <div class="title">
            <div class="choose-container">
                <div class="choose-map">
                    <div class="choose">
                        <h1>Válasszon egészségügyi szolgáltatásaink közül!</h1>
                    </div>
                    <div class="choose">
                        <h3>Kényelmes időpontfoglalás és szakszerű ellátás</h3>
                    </div>
                    <div class="choose">
                        <p>
                            A Regenero budapesti magán egészségügyi központban az Ön egészsége a legfontosabb számunkra! Tapasztalt orvosi
                            csapatunk és modern felszereléseink biztosítják, hogy a legmagasabb szintű ellátásban részesüljön. A személyre
                            szabott figyelem és gyors, kényelmes szolgáltatások mind azt a célt szolgálják, hogy Ön a legjobb formáját
                            hozhassa.
                        </p>
                    </div>
                    <div class="choose">
                        <p>Keressen minket bátran, szívesen segítünk, vagy az alábbi gombra kattintva is ellátogathat hozzánk!</p>
                    </div>
                    <div class="choose">
                        <button href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">IDŐPONT FOGLALÁSA</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- routerLink="/specialitydoctors/{{ speciality.id }}" - class= speciality element routerLinkje -->
    <div [ngStyle]="{ width: '90%' }" class="speciality">
        <div class="spec">
            <div
                routerLink="/consultation/{{ speciality.id }}"
                *ngFor="let speciality of specialities; let i = index"
                class="speciality-element"
            >
                <div class="name-container">
                    <div class="name">
                        <div>
                            <img [src]="speciality.imageUrl" />
                        </div>
                        <p>{{ speciality.name | uppercase }}</p>
                    </div>
                </div>
            </div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
            <div class="placeholder"></div>
        </div>
    </div>
    <div class="reserve-container">
        <div class="reserve">
            <div class="res">
                <h2>Miért válassza a mi rendelőnket?</h2>
            </div>
            <div class="res"><p>Egészségügyi központunkban minőségi szolgáltatást nyújtunk, ahol Ön az első!</p></div>
            <div class="res">
                <ul>
                    <li>Magas színvonalú szakellátás, személyre szabott kezelésekkel</li>
                    <li>Kiváló, folyamatosan fejlődő és bővülő orvosi csapatunk az Ön rendelkezésére áll</li>
                    <li>Kényelmes, gyors bejelentkezési online időpontfoglaló rendszerünkön keresztül</li>
                    <li>
                        Magánorvosi rendelés keretein belül várjuk kedves pácienseinket az Önnek megfelelő időpotban, gyors sorrakerüléssel
                    </li>
                    <li>Nem kell külön laborba mennie, házon belül intézzük őket</li>
                </ul>
            </div>
            <div class="res">
                <p>
                    Biztosak vagyunk benne, ha a mi szakrendeléseinket választja, gyors szakértői választ kaphat egészségével kapcsolatos
                    kérdéseire!
                </p>
            </div>
            <div class="res"><p>Ne habozzon, az Ön egészsége számunkra a legfontosabb!</p></div>
            <div class="res">
                <button href="https://medicall.cc/idopontfoglalas/regenero/index" target="_blank">FOGLALJON HOZZÁNK IDŐPONTOT!</button>
            </div>
        </div>
    </div>
    <div>
        <!--<h2>Pácienseink visszajelzései!</h2>-->
    </div>
</div>
