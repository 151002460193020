<div class="speciality-pricelist-container">
    <div class="title-container">
        <div class="title">
            <div class="pricelist">
                <h2>RENDELÉSI IDŐK</h2>
            </div>
        </div>
    </div>
    <div class="speciality">
        <div class="icons"></div>
        <div class="spec">
            <div *ngFor="let speciality of specialities; let i = index" class="speciality-element">
                <div class="name-container">
                    <div (click)="chooseOneSpeciality(speciality)" class="name">
                        <p>
                            <b [ngStyle]="{ 'font-size': '18px' }">{{ speciality.name! | uppercase }}</b>
                        </p>
                    </div>
                    <div *ngIf="speciality.hasSpecialityChosen && speciality.isVisible" class="exam">
                        <div class="examination-container" *ngFor="let doctorspeciality of speciality.doctorSpecialityList; let i = index">
                            <div routerLink="/doctor/{{ doctorspeciality.doctor?.routerLink }}" class="examination">
                                <div [ngStyle]="{ 'flex-direction': 'column' }" class="examination-name">
                                    <div class="image">
                                        <img [ngStyle]="{ width: '100px' }" [src]="doctorspeciality.doctor?.thumbnailUrl" />
                                    </div>
                                </div>
                                <div class="consulting-hour-container">
                                    <b *ngIf="doctorspeciality.doctor?.name !== 'labor'">{{ doctorspeciality.doctor?.name }}</b>
                                    <b>Rendelési idő:</b>
                                    <div class="consult">
                                        <div *ngFor="let consultingHour of doctorspeciality.doctor?.doctorConsultingHourList">
                                            <div class="consulting">
                                                <span>{{ consultingHour.day }}:</span>
                                                <span *ngIf="consultingHour.startTime !== null && consultingHour.endTime !== null">
                                                    {{ consultingHour.startTime }} - {{ consultingHour.endTime }}
                                                </span>
                                                <span *ngIf="consultingHour.startTime === null && consultingHour.endTime === null">
                                                    változó</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="consulting-hour-container">
                                <div *ngIf="doctorspeciality.doctor?.name !== 'Labor'" class="image">
                                    <img [ngStyle]="{ width: '100px' }" [src]="doctorspeciality.doctor?.image" />
                                </div>
                                <span>{{ doctorspeciality.doctor?.name }}</span>
                                <h3>Rendelési idő:</h3>
                                <div class="consult">
                                    <div
                                        *ngFor="let consultingHour of doctorspeciality.doctor?.doctorConsultingHourList!"
                                        class="consulting-hours"
                                    >
                                        <div class="consulting-hour">
                                            <span>{{ consultingHour.day }}</span>
                                            <span *ngIf="consultingHour.startTime !== null && consultingHour.endTime !== null">
                                                {{ consultingHour.startTime }} - {{ consultingHour.endTime }}
                                            </span>
                                            <span *ngIf="consultingHour.startTime === null && consultingHour.endTime === null"
                                                >változó</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
