<div class="blog-container">
    <div class="blog">
        <h1>Híreink</h1>
        <h4>TRIDENT™ NANO: HOSSZÚ TÁVÚ HOLTER MONITOR MAGYARORSZÁGON ELSŐKÉNT</h4>
        <div class="holter">
            <h3>Magyarországon elsőként: a legkorszerűbb HOLTER – EKG monitorizálás</h3>
            <p>
                Napjainkban a hagyományosan 24 órás, a testre csak vaskos kábelköteggel felszerelhető, kényelmetlen Holter készülékek
                szerepét számos országban fokozatosan átveszik a modernebb, megbízhatóbb eszközök, melyek kialakítása jóval egyszerűbb, és a
                technológiai újításoknak köszönhetően már a páciensek számára is lényegesen kényelmesebb viseletet biztosítanak.
                Magyarországon ennek ellenére a hosszú távú Holter vizsgálatok még nem terjedtek el. Ha viselt már hagyományos Holter
                készüléket, akár csak egy napig is, akkor Ön is jól ismeri az ezzel járó kényelmetlenségeket, amelyek az orvosi szempontból
                indokolt esetekben is gyakran nehezítik meg a vizsgálatok megismétlését. Pedig a nemzetközi szakmai állásfoglalás alapján a
                legtöbb ritmuszavar esetén a 24 óránál hosszabb távú vizsgálatok végzése javasolt a helyes diagnózis felállítása érdekében.
            </p>
            <p>
                Ha szívdobogásérzéstől vagy igazolt szívritmuszavartól szenved, vagy szűrővizsgálaton vesz részt orvosa tanácsára, ne
                vesződjön a hagyományos Holter vizsgálatokkal!
            </p>
            <p>
                A Regenero Egészségház a NextGenMed Hungary Kft.-vel együttműködésben Magyarországon elsőként, egyedülálló orvostechnikai
                eszközként vette használatba a Trident™ Nano hosszú távú Holter monitort.
            </p>
            <p>
                Az elektródakábeleket nem igénylő Trident™ Nano a modern Holter készülékek sorából is kiemelkedik megbízhatóságával,
                vízállóságával és igen apró méretéből adódó kényelmes viseletével:
            </p>
            <ul>
                <li>Elektródatapaszával a mellkas bőrén rögzül, teljesen észrevétlenül, mind a beteg, mind környezete számára.</li>
                <li>Ezáltal nem befolyásolja a mindennapi munkavégzést és a páciens megjelenését sem érinti hátrányosan.</li>
                <li>
                    A Holter készülék súlya kevesebb, mint 30 gramm, és lapos kialakítása miatt senki sem fogja észrevenni, ha Ön nem
                    akarja.
                </li>
                <li>
                    A készülék és a tapasz is vízálló kialakítású, így a több napon át tartó vizsgálatok során a tusolás, tisztálkodás sem
                    jelenthet gondot.
                </li>
                <li>
                    Ez egyben azt is jelenti, hogy a vizsgálatot nem kell megszakítani, melyről a Holter készülék nagy kapacitású Li-ion
                    akkumulátora is gondoskodik.
                </li>
            </ul>
            <p>
                A NextGenMed Hungary Kft. partnereként a Regenero Egészségház a Holter vizsgálatok gyorsasága és pontossága érdekében a
                Holter felvevőeszközt automatizált elemzésére képes szoftverrel egészítette ki. Ez a fejlett, mesterséges intelligenciát
                használó online, leletezőtechnológia a bázeli orvostudományi egyetem kardiológiai klinikájával együttműködésben került
                kifejlesztésre. Az online elemzés használata a leletezési időt jelentősen lerövidíti, így az orvosok a leletezés helyett a
                kiértékelés alapján pontosabb diagnózis felállítására fordíthatják idejüket. A páciensek egy átlátható, könnyen érthető, de
                ugyanakkor kellően részletes lelethez jutnak, mely minőségében messze felülmúlja az eddig megszokott összefoglaló
                véleményekét.
            </p>
            <p>Pitvarfibrilláció szűrés:</p>
            <p>
                Egyes szívritmuszavarok a szíven belüli vérrögképződés kockázatát jelentős mértékben növelik. Ezek a vérrögök a szívből a
                verőereken keresztül elsodródva stroke-ot (szélütés), végtagi ütőérelzáródást vagy bélelhalást okozhatnak. Ezen betegséget
                mindegyike potenciálisan végzetes kimenetelű. Magyarországon évente mintegy 45.000 stroke esemény következik be. Nemzetközi
                irodalmi adatok alapján ezek 10-14%-a hátterében időben fel nem ismert és emiatt nem kezelt szívritmuszavar, döntően
                pitvarfibrilláció áll. Több fejlett országban már felismerték ezt a problémát, így napjainkban is folynak nemzeti
                szűrőprogramok a veszélyeztetett korcsoportba tartozók részvételével (többek között az Egyesült Királyságban). A nehézség
                abban rejlik, hogy a pitvarfibrilláció és vele rokon szívritmuszavarok nem feltétlenül okoznak panaszt. Egészségünk a
                legnagyobb kincsünk, megóvásáért minden esetben szükséges tennünk. Ezért, ha Ön betöltötte a 65. életévét, orvosával
                konzultálva javasolt időszakosan Holter vizsgálatot végeztetnie az esetleges pitvarfibrilláció felismerése érdekében.
            </p>
            <p>Ebben állunk rendelkezésére.</p>
        </div>
    </div>
</div>
