<div class="appointment-container">
    <div class="appointment">
        <div class="close-button">
            <mat-icon (click)="closeDialog()">close</mat-icon>
        </div>
        <div class="speciality-name">
            <h3>{{ specialityName | uppercase }} SZAKRENDELÉS</h3>
        </div>
        <div *ngFor="let doctor of doctorWithConsultingHours" class="doctors">
            <div routerLink="/doctor/{{ doctor.doctor?.id }}" (click)="closeDialogg()" class="doctor-image-container">
                <div class="image">
                    <img [src]="doctor.doctor?.image" />
                </div>
                <div class="name">
                    <label>{{ doctor.doctor?.name }}</label>
                </div>
                <div class="title">
                    <!-- <label>{{ doctor.doctor?.title }}</label> -->
                </div>
                <div class="consulting-title">
                    <span>Rendelési idő:</span>
                </div>
                <div class="consulting-hour-container">
                    <div class="consult">
                        <div *ngFor="let consultingHour of doctor.consultingHours" class="consulting-hours">
                            <div class="consulting-hour">
                                <span *ngIf="consultingHour.startTime !== null && consultingHour.endTime !== null">
                                    <span>{{ consultingHour.day }}: </span> {{ consultingHour.startTime }} - {{ consultingHour.endTime }}
                                </span>
                                <span *ngIf="consultingHour.startTime === null && consultingHour.endTime === null">
                                    <span>{{ consultingHour.day }}: </span>változó</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
