import { Component, Inject, OnInit } from '@angular/core';
import { HomeConfig } from './home-config';
import { ApiRequestService, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HomeConfigResponse } from './homeconfigresponse';

@Component({
  selector: 'pw-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    getHomeConfigsUrl = '/websiteconfigurator/get-homeconfigs';

    homeConfigList:HomeConfig[] = [];

    configuratorBaseUrl = '';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this._apiRequestService
        .sendRequest<{}, HomeConfigResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeConfigsUrl)
        .subscribe((response) => {
            this.homeConfigList = response.homeConfigList as HomeConfig[];
        });

    }
}
