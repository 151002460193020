import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
    BreadcrumbModule,
    FooterModule,
    HeadermoduleModule,
    IntroductionModule,
    LanguageModule,
    LogLevel,
    SharedModule,
    TranslationModule,
    SlideshowModule,
    NavigationModule,
    GoogleMapsOwnModule,
    FreshNewsModule,
    BlogModule
} from '@prekog/pw-common';
import { HomeComponent } from './home/home.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { DoctorComponent } from './doctor/doctor.component';
import { IframeUrlPipe } from './doctor/iframeurlpipe';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { HealthfundComponent } from './healthfund/healthfund.component';
import { SpecialityDoctorsComponent } from './specialitydoctors/specialitydoctors.component';
import { PricelistComponent } from './pricelist/pricelist.component';
import { NavigationregeneroComponent } from './navigationregenero/navigationregenero.component';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { SpecialitydescriptionComponent } from './specialitydescription/specialitydescription.component';
import { ConsultinghoursComponent } from './consultinghours/consultinghours.component';
import { BlogComponent } from './blog/blog.component';
import { MovingpopupComponent } from './movingpopup/movingpopup.component';
import { BlogsComponent } from './blogs/blogs.component';
import { GyikComponent } from './gyik/gyik.component';

registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ConsultationComponent,
        DoctorComponent,
        IframeUrlPipe,
        SpecialityDoctorsComponent,
        HealthfundComponent,
        PricelistComponent,
        NavigationregeneroComponent,
        SpecialitydescriptionComponent,
        ConsultinghoursComponent,
        MovingpopupComponent,
        BlogComponent,
        BlogsComponent,
        GyikComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslationModule.forRoot({ translatedLanguages: ['hu'] }),
        LanguageModule.forRoot(),
        BreadcrumbModule.forRoot(),
        FooterModule,
        HeadermoduleModule,
        NavigationModule,
        AppRoutingModule,
        SharedModule.forRoot({ logLevel: LogLevel.Info, enableUseCaseStatusLogs: true }), //TODO: from environment.properties
        IntroductionModule.forRoot({isRowView:false}),
        SlideshowModule,
        MatRadioModule,
        GoogleMapsModule,
        GoogleMapsOwnModule,
        FreshNewsModule


    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'black' }
        },
        { provide: 'CONFIGURATOR_BASE_URL', useValue: environment.backendConfiguratorUrl },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'DOWNLOAD_FILE_URL', useValue: environment.downloadUrl },
        { provide: 'PRODUCTION', useValue: environment.production },
        { provide: 'REGENERO_BASE_URL', useValue: environment.regeneroBaseUrl }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
