<mat-toolbar [ngStyle]="{ background: '#f0f5f5' }">
    <mat-toolbar-row [ngStyle]="{ background: '#f0f5f5', 'margin-top': '32px' }"
        ><pw-headermodule
            [nameOfTheSite]="nameOfTheSite"
            [logoUrl]="logoUrl"
            [facebookImgUrl]="facebookImgUrl"
            [facebookURL]="facebookUrl"
            [hasSearchBar]="false"
            [hasLogo]="true"
            [hasShoppingCart]="false"
            [hasLogin]="false"
            [colorOfNavBar]="colorOfNavBar"
            [logoSize]="logoSize"
            [isRegenero]="true"
            [navigationMenu]="navigationMenu"
            [hasMatMenu]="false"
            [aszfUrl]="aszfUrl"
            [dataManagamentUrl]="dataManagamentUrl"
        ></pw-headermodule
    ></mat-toolbar-row>
    <mat-toolbar-row [ngStyle]="{ background: '#413C3F' }">
        <button mat-icon-button aria-label="Menu icon" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="home" routerLink="/">
            <h1>Regenero Egészségház</h1>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fullscreen>
    <mat-sidenav [ngClass]="!isCollapsed ? 'expanded' : ''" [mode]="isMobile ? 'over' : 'side'" [opened]="isMobile ? 'false' : 'true'">
        <mat-nav-list *ngFor="let navigation of navigationMenu">
            <a *ngIf="!navigation.externalLink" (click)="routeOnClick(navigation)" mat-list-item>
                <div class="entry">
                    <!-- <mat-icon>{{navigation.matIcon}}</mat-icon> -->
                    <span>{{ navigation.translationKey }}</span>
                    <div class="icon">
                        <mat-icon *ngIf="navigation.hasDynamicSubMenu" class="fa fa-angle-down">keyboard_arrow_right</mat-icon>
                    </div>
                </div>
            </a>
            <a *ngIf="navigation.externalLink" href="{{ navigation.externalLink }}" mat-list-item target="_blank">
                <div class="entry">
                    <!-- <mat-icon>{{navigation.matIcon}}</mat-icon> -->
                    <span>{{ navigation.translationKey }}</span>
                    <div class="icon">
                        <mat-icon *ngIf="navigation.hasDynamicSubMenu" class="fa fa-angle-down">keyboard_arrow_right</mat-icon>
                    </div>
                </div>
            </a>
            <div class="submenu-container" *ngIf="submenuOpened && navigation.id === id">
                <div *ngFor="let submenuElement of navigation.staticSubMenus">
                    <a *ngIf="!submenuElement.hasDynamicSubMenu" (click)="routeOnClick(submenuElement)" mat-list-item>
                        <span class="entry-sub">
                            <!-- <mat-icon>{{navigation.matIcon}}</mat-icon> -->
                            <span>{{ submenuElement.translationKey }}</span>
                            <div class="icon">
                                <mat-icon *ngIf="submenuElement.hasDynamicSubMenu" class="fa fa-angle-down">keyboard_arrow_right</mat-icon>
                            </div>
                        </span>
                    </a>
                    <a *ngIf="submenuElement.hasDynamicSubMenu" (click)="routeOnClickk(submenuElement)" mat-list-item>
                        <span class="entry-sub">
                            <!-- <mat-icon>{{navigation.matIcon}}</mat-icon> -->
                            <span>{{ submenuElement.translationKey }}</span>
                            <div class="icon">
                                <mat-icon *ngIf="submenuElement.hasDynamicSubMenu" class="fa fa-angle-down">keyboard_arrow_right</mat-icon>
                            </div>
                        </span>
                    </a>
                    <div class="submenu-container" *ngIf="subsubmenuopened && submenuElement.id === idSub">
                        <div *ngFor="let sub of submenu">
                            <a (click)="routeOnClick(sub)" mat-list-item>
                                <span class="entry-sub">
                                    <!-- <mat-icon>{{navigation.matIcon}}</mat-icon> -->
                                    <span>{{ sub.translationKey }}</span>
                                    <div class="icon">
                                        <mat-icon *ngIf="sub.hasDynamicSubMenu" class="fa fa-angle-down">keyboard_arrow_right</mat-icon>
                                    </div>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div
            [ngStyle]="{ width: '100%', display: 'flex', 'justify-content': 'center', 'flex-direction': 'column' }"
            class="router-outlet-container"
        >
            <div [ngStyle]="{ width: '100%' }" class="rooter-outlet">
                <router-outlet></router-outlet>
            </div>
            <div class="footer">
                <pw-footermodule [footer]="footer"></pw-footermodule>
                <ng-template #footer>
                    <div class="elements">
                        <div class="documents">
                            <div class="content">
                                <div class="documents-inside-box">
                                    <div class="privacy-policy">
                                        <a class="policy" routerLink="/healthfund" target="_blank"><span>EGÉSZSÉGPÉNZTÁRAK</span></a>
                                    </div>
                                    <div class="aszf">
                                        <a class="aszf" href="{{ aszfUrl }}" target="_blank"
                                            ><span>{{ 'footer.aszf' | translate }}</span></a
                                        >
                                    </div>
                                    <div class="privacy-policy">
                                        <a class="policy" href="{{ dataManagamentUrl }}" target="_blank"
                                            ><span>{{ 'footer.policy' | translate }}</span></a
                                        >
                                    </div>
                                </div>
                                <div class="links">
                                    <a href="http://prekogit.hu/"><span>Informatikai megoldások</span></a>
                                    <a href="http://www.regenero.hu/"><span>Egészségügy</span></a>
                                    <a href="http://prekogalfa.hu/"><span>Felnőttképzés</span></a>
                                </div>
                                <div class="logo"><img src="{{ logoUrl }}" /></div>
                            </div>
                        </div>
                        <div class="regenero-infos">
                            <div class="contact-container">
                                <div class="regenero">
                                    <a routerLink="/">{{ 'footer.regenero' | translate }}</a
                                    ><br />
                                </div>
                                <div class="contact">
                                    <div class="span-container">
                                        <span>{{ 'footer.emailAddress' | translate }}</span>
                                    </div>
                                    <div class="span-container">
                                        <span>{{ 'footer.address' | translate }}</span>
                                    </div>
                                    <div class="span-container">
                                        <span>{{ 'footer.phoneNumber' | translate }}</span>
                                    </div>
                                    <div class="span-container-opening">
                                        <span>{{ 'footer.openingHours' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
